import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 50,

    [theme.breakpoints.up('768')]: {
      width: '85%',
      paddingTop: 100,
      paddingBottom: 100,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 1600,
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  subHeaderContainer: {
    width: '80%',
    minWidth: 260,

    [theme.breakpoints.up('768')]: {
      width: '100%',
    },

    [theme.breakpoints.up('1040')]: {
      maxWidth: 714,
    },

    [theme.breakpoints.up('1200')]: {
      maxWidth: 1060,
    },
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(115, 115, 115, 1)',
    fontFamily: 'poppins',
    lineHeight: '18px',
    textAlign: 'center',
    [theme.breakpoints.up('1040')]: {
      fontSize: 12,
      lineHeight: '24px',
    },

    [theme.breakpoints.up('1200')]: {
      fontSize: 16,
    },
  },
  header: {
    fontSize: 18,
    fontWeight: 800,
    fontFamily: 'poppins',

    [theme.breakpoints.up('1040')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 25,
    },
  },
  headerContainer: {
    width: '60%',
    textAlign: 'center',
    padding: 15,
    minWidth: 250,

    [theme.breakpoints.up('768')]: {
      width: '85%',
      padding: 30,
    },

    [theme.breakpoints.up('1040')]: {
      maxWidth: 550,
    },
    [theme.breakpoints.up('1200')]: {
      maxWidth: 750,
    },
  },
}))

export default function ProprietarySection({ topSubHeader, bottomSubHeader }) {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <div className={classes.subHeaderContainer}>
        {topSubHeader ? (
          <Typography className={classes.subHeader}>{topSubHeader}</Typography>
        ) : (
          <Typography className={classes.subHeader}>Sub Header</Typography>
        )}
      </div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          <span style={{ color: '#2FA43B' }}>Cycurid’s </span>
          Proprietary Software Enables Security, Privacy and Decentralization to{' '}
          <span style={{ color: '#2FA43B' }}>WEB3 </span>
          Technologies
        </Typography>
      </div>
      <div className={classes.subHeaderContainer}>
        {bottomSubHeader ? (
          <Typography className={classes.subHeader}>
            {bottomSubHeader}
          </Typography>
        ) : (
          <Typography className={classes.subHeader}>Sub Header</Typography>
        )}
      </div>
    </div>
  )
}

ProprietarySection.propTypes = {
  topSubHeader: PropTypes.string,
  bottomSubHeader: PropTypes.string,
}

ProprietarySection.defaultProps = {
  topSubHeader: '',
  bottomSubHeader: '',
}
