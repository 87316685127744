import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Fade } from 'react-awesome-reveal'
import { StaticImage } from 'gatsby-plugin-image'

const ImmeAppScreenShot = '../../../assets/Home/Images/Introducing imme.png'

const useStyles = makeStyles((theme) => ({
  immeIntroSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    position: 'relative',
    justifyContent: 'center',

    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,
    paddingBottom: 30,
    marginTop: 80,

    [theme.breakpoints.up('tabletV2')]: {
      marginTop: 0,
      flexDirection: 'row',
    },

    [theme.breakpoints.up('desktop')]: { marginTop: 100, paddingBottom: 100 },
    [theme.breakpoints.up('desktopXL')]: {},
  },
  introInformation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tabletV2')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: '10%',
    },
  },
  introTitle: {
    textAlign: 'center',
    maxWidth: 280,

    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
      fontSize: 'calc(9px + 0.7vw)',
      maxWidth: '100%',
    },

    [theme.breakpoints.up('700')]: {
      fontSize: 'calc(10px + 0.7vw)',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 'calc(15px + 0.7vw)',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: '25px',
    },
  },
  introBody: {
    marginTop: '15px',
    textAlign: 'center',
    padding: '0 30px',
    color: 'rgba(115, 115, 115, 1)',
    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
      maxWidth: 500,
      padding: 0,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 16,
    },
  },
  imageWrapper: {
    // zIndex: 1,
    // position: 'absolute',
    // top: '280px',
    margin: 60,

    [theme.breakpoints.up('tabletV2')]: {
      margin: 50,
    },
    [theme.breakpoints.up('desktop')]: {},
  },
  immeAppScreenshot: {
    minWidth: 180,
    minHeight: 365,
    width: '70%',
    height: '70%',
    [theme.breakpoints.up('tabletV2')]: {
      width: 200,
      height: 415,
    },
    [theme.breakpoints.up('desktop')]: {
      width: 260,
      height: 543,
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: 290,
      height: 593,
    },
  },
}))

export default function MobileSection() {
  const classes = useStyles()

  return (
    <section className={classes.immeIntroSection}>
      <div className={classes.imageWrapper}>
        <Fade
          triggerOnce
          delay={400}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StaticImage
            className={classes.immeAppScreenshot}
            src={ImmeAppScreenShot}
            alt='Imme email registration screenshot'
            placeholder='tracedSVG'
            transformOptions={{ fit: 'inside' }}
            quality={100}
          />
        </Fade>
      </div>
      <div className={classes.introInformation}>
        <Fade triggerOnce delay={350}>
          <Typography variant='h2' className={classes.introTitle}>
            Simple, Effortless, and Secure
          </Typography>
          <Typography variant='body1' className={classes.introBody}>
            To get your imme digital identity, it is as simple as validating
            your email, phone number, doing a liveness check. Our proprietary AI
            app will validate your identity and confirm that you are you and
            that your ID has not compromised by someone else.
          </Typography>
          <Typography variant='body1' className={classes.introBody}>
            Once the verification process is completed, your personal
            information and ID are encrypted on your device using our unique
            biometric encryption process. Making your personal information truly
            yours and secure.
          </Typography>
          <Typography variant='body1' className={classes.introBody}>
            You can now use your Imme Digital Identity to gain secure and
            instant access to your online services while protecting you from
            identity theft and data breaches.
          </Typography>
          <Typography variant='body1' className={classes.introBody}>
            You retain complete control over your own identity data and keep
            track of your identity and the online services you have used.
          </Typography>
        </Fade>
      </div>
    </section>
  )
}

// "To get your CycurID digital identity, it is as simple as taking a picture of your driver license or passport, login to your bank account and a selfie. Our proprietary AI app will validate your identity and ID, confirm that it is you and that your ID has not been compromised by someone else.

// Once the verification process is completed, your personal information and ID are encrypted on your device using our unique biometric encryption process. Making your personal information truly yours and secure.

// You can now use your CycurID Digital Identity to gain secure and instant access to your online services while protecting you from identity theft and data breaches.

// You retain complete control over your own identity data and keep track of your identity and the online services you have used.
// "
