import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CTALink from '../../../components/CTALink'
import LiberatedIcon from '../../../assets/Home/Icons/liberated.svg'
import EncryptedIcon from '../../../assets/Home/Icons/encrypted.svg'
import IdentityIcon from '../../../assets/Home/Icons/identity.svg'
import TrackIcon from '../../../assets/Home/Icons/track.svg'
import routes from '../../../routes'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    maxWidth: 1600,
    flexDirection: 'column',

    [theme.breakpoints.up('768')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    [theme.breakpoints.up('1040')]: {
      height: 460,
    },
    [theme.breakpoints.up('1200')]: {
      height: 600,
    },
  },
  headerContainer: {
    width: '80%',

    [theme.breakpoints.up('768')]: {
      display: 'flex',
      width: '90%',
    },
  },
  header: {
    color: '#2FA43B',
    fontSize: 12,
    textAlign: 'center',

    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
    },
  },
  infoSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '95%',

    [theme.breakpoints.up('768')]: {
      alignItems: 'flex-start',
      width: '90%',
    },
  },
  infoHeader: {
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 15,
    padding: 12,
    color: 'white',

    [theme.breakpoints.up('768')]: {
      padding: 0,
      paddingBottom: 10,
      paddingTop: 10,
    },
    [theme.breakpoints.up('1040')]: {
      fontSize: 18,
    },

    [theme.breakpoints.up('1200')]: {
      height: 25,
    },
  },
  infoBody: {
    fontFamily: 'poppins',
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    width: '80%',

    [theme.breakpoints.up('768')]: {
      textAlign: 'left',
      width: '95%',
      maxWidth: 400,
    },

    [theme.breakpoints.up('1040')]: {
      lineHeight: '18px',
      maxWidth: 380,
    },

    [theme.breakpoints.up('1200')]: {
      fontSize: 16,
      maxWidth: 500,
      lineHeight: '24px',
      marginTop: 30,
    },
  },
  cardContainer: {
    width: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('768')]: {
      width: 'calc(300px + 10vw)',
      height: 'calc(260px + 10vw)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      flexWrap: 'wrap',
      paddingRight: '3vw',
      flexDirection: 'row',
    },
    [theme.breakpoints.up('1040')]: {
      height: 320,
      width: 410,
      paddingRight: '0px',
    },
    [theme.breakpoints.up('1200')]: {
      height: 410,
      width: 480,
    },
  },
  card: {
    background: 'white',
    width: '80%',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: '1px solid #CCCCCC',
    marginBottom: 7,

    [theme.breakpoints.up('768')]: {
      height: '46%',
      width: '48%',
      flexDirection: 'column',
      marginBottom: 0,
      margin: 2,
    },
    [theme.breakpoints.up('1040')]: {
      height: 155,
      width: 186,
      flexDirection: 'column',
      marginBottom: 0,
      margin: 2,
    },
    [theme.breakpoints.up('1200')]: {
      height: 200,
      width: 230,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '25%',

    [theme.breakpoints.up('768')]: {
      width: '100%',
      height: '50%',
    },
    [theme.breakpoints.up('1200')]: {
      height: '60%',
    },
  },
  iconSize: {
    [theme.breakpoints.up('768')]: {
      height: 65,
      width: 65,
    },
    [theme.breakpoints.up('1040')]: {
      height: 73,
      width: 73,
      marginTop: 10,
    },
    [theme.breakpoints.up('1200')]: {
      height: 90,
      width: 90,
      marginTop: 0,
    },
  },
  cardInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '75%',

    [theme.breakpoints.up('768')]: {
      width: '100%',
      height: '50%',
    },
    [theme.breakpoints.up('1040')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('1200')]: {
      height: '40%',
    },
  },
  cardInfo: {
    color: 'black',
    fontSize: 10,
    lineHeight: '18px',
    width: '95%',
    fontWeight: 600,
    fontFamily: 'poppins',
    minWidth: 160,

    [theme.breakpoints.up('768')]: {
      fontSize: 12,
      minWidth: 100,
      textAlign: 'center',
      padding: 10,
      width: 150,
    },

    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
      width: 170,
    },
  },
  buttonContainer: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',

    [theme.breakpoints.up('768')]: {
      display: 'none',
    },
  },
  button: {
    background: '#2FA43B',
    width: '80%',
    height: 52,
    borderRadius: 8,
    border: '1px solid #2FA43B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    color: 'white',
  },
  individualButtonContainer: {
    cursor: 'pointer',
    display: 'none',

    [theme.breakpoints.up('768')]: {
      width: '90%',
      display: 'flex',
      alignItems: 'flex-start',
    },

    [theme.breakpoints.up('1040')]: {},

    [theme.breakpoints.up('1200')]: {
      marginTop: 10,
    },
  },
  individualSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('768')]: {
      width: '55%',
      maxWidth: 650,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('1200')]: {
      marginRight: 20,
    },
  },
  signUpButton: {
    width: '95%',
    fontSize: '16px',
    marginTop: 30,

    [theme.breakpoints.up('tabletV2')]: {
      fontSize: '14px',
      fontWeight: 500,
      padding: 0,
      marginRight: 16,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '136px',
      marginLeft: 40,
      marginTop: '4%',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      padding: 0,
      marginRight: 10,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '160px',
    },
  },
  greenTitle: {
    color: '#2FA43B',
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '17px',
    letterSpacing: '0.5px',
    padding: '0 40px',
    textAlign: 'center',
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
    },
  },
  titleText: {
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
    color: '#FFFFFF',
    padding: '0 40px',
    textAlign: 'center',
    marginTop: 5,

    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('1330')]: {
      fontSize: 22,
    },
  },
  contentText: {
    fontFamily: 'poppins',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
    color: '#FFFFFF',
    padding: '0 40px',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 30,
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
      marginTop: 10,
    },
    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
      marginBottom: 0,
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: 13,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
      lineHeight: '24px',
      width: 600,
    },
    [theme.breakpoints.up('1330')]: {
      fontSize: 16,
    },
  },
}))

export default function IndividualSection({ header, subHeader }) {
  const classes = useStyles()

  const cards = [
    {
      id: 0,
      icon: <IdentityIcon className={classes.iconSize} />,
      body: `Nothing is shared without your explicit permission`,
    },
    {
      id: 1,
      icon: <EncryptedIcon className={classes.iconSize} />,
      body: `Encrypted files stored on your portable device`,
    },
    {
      id: 2,
      icon: <TrackIcon className={classes.iconSize} />,
      body: `Keep track of your digital activities and footprint`,
    },
    {
      id: 3,
      icon: <LiberatedIcon className={classes.iconSize} />,
      body: `Be liberated from memorizing dozens of passwords`,
    },
  ]

  return (
    <div className={classes.main}>
      <div className={classes.individualSection}>
        <div className={classes.headerContainer}>
          <Typography className={classes.greenTitle}>INDIVIDUAL</Typography>
        </div>
        <div className={classes.infoSection}>
          <Typography className={classes.titleText}>{header}</Typography>
          <Typography className={classes.contentText}>{subHeader}</Typography>
        </div>
        <div className={classes.individualButtonContainer}>
          <CTALink className={classes.signUpButton} href={routes.INDIVIDUALS}>
            Learn More
          </CTALink>
        </div>
      </div>
      <div className={classes.cardContainer}>
        {cards.map((card) => {
          return (
            <div key={card.id} className={classes.card}>
              <div className={classes.iconContainer}>{card.icon}</div>
              <div className={classes.cardInfoContainer}>
                <Typography className={classes.cardInfo}>
                  {card.body}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.buttonContainer}>
        <CTALink className={classes.signUpButton} href={routes.INDIVIDUALS}>
          Learn More
        </CTALink>
      </div>
    </div>
  )
}

IndividualSection.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
}

IndividualSection.defaultProps = {
  header: '',
  subHeader: '',
}
