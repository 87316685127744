import React from 'react'
import { makeStyles } from '@material-ui/core'
import Circle from './Circle'

const shadow1 = {
  mobile: '0px 5.13682px 10.2736px rgba(30, 66, 101, 0.4)',
  tablet: '0px 10.1174px 20.2349px rgba(30, 66, 101, 0.4)',
  desktopXL: '0px 15px 30px rgba(30, 66, 101, 0.4)',
}

const shadow2 = {
  mobile: '0px 3.42455px 17.1227px rgba(47, 164, 59, 0.4)',
  tablet: '0px 6.74495px 33.7248px rgba(47, 164, 59, 0.4)',
  desktopXL: '0px 10px 50px rgba(47, 164, 59, 0.4)',
}

const useStyles = makeStyles((theme) => ({
  facebook: {
    left: 100.29,
    top: 450.27,
    boxShadow: shadow1.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '31.769vw',
      top: '17.739vw',
      boxShadow: shadow1.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 471,
      top: 63,
      boxShadow: shadow1.desktopXL,
    },
  },
  syniverse: {
    left: 17.29,
    top: 600.18,
    boxShadow: shadow1.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '5.396vw',
      top: '29.61vw',
      boxShadow: shadow1.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 80,
      top: 239,
      boxShadow: shadow1.desktopXL,
    },
  },
  syniverseText: {
    '& b': {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 700,
      [theme.breakpoints.up('tablet')]: {
        fontSize: '2.4vw',
        lineHeight: '3.5vw',
      },
      [theme.breakpoints.up('desktopXL')]: {
        fontSize: 35,
        lineHeight: '52px',
      },
    },
  },
  marriott: {
    left: 258.23,
    top: 524.85,
    boxShadow: shadow2.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '61.312vw',
      top: '25.294vw',
      boxShadow: shadow2.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 909,
      top: 175,
      boxShadow: shadow2.desktopXL,
    },
  },
  marriottText: {
    '& b': {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 700,
      [theme.breakpoints.up('tablet')]: {
        fontSize: '2.3vw',
        lineHeight: '3.4vw',
      },
      [theme.breakpoints.up('desktopXL')]: {
        fontSize: 35,
        lineHeight: '52px',
      },
    },
  },
  twitter: {
    left: 255.36,
    top: 681.93,
    boxShadow: shadow1.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '69.878vw',
      top: '44.179vw',
      boxShadow: shadow1.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 1036,
      top: 455,
      boxShadow: shadow1.desktopXL,
    },
  },
  microsoft: {
    left: 172.2,
    top: 645.46,
    boxShadow: shadow1.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '46.878vw',
      top: '51.734vw',
      boxShadow: shadow1.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 695,
      top: 567,
      boxShadow: shadow1.desktopXL,
    },
  },
  canva: {
    left: 104.18,
    top: 670.84,
    boxShadow: shadow2.mobile,
    [theme.breakpoints.up('tablet')]: {
      left: '23.135vw',
      top: '46.877vw',
      boxShadow: shadow2.tablet,
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 343,
      top: 495,
      boxShadow: shadow2.desktopXL,
    },
  },
  yahoo: {
    left: 219.61,
    top: 444.68,
    [theme.breakpoints.up('tablet')]: {
      left: '55.342vw',
      top: '17.515vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 811.5,
      top: 54.5,
    },
  },
  deepRootAnalytics: {
    left: 52.14,
    top: 444.68,
    [theme.breakpoints.up('tablet')]: {
      left: '13.557vw',
      top: '17.334vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 210,
      top: 66,
    },
  },
  deepRootAnalyticsText: {
    width: 37,
    lineHeight: '8px',
    [theme.breakpoints.up('tablet')]: {
      width: '7.1vw',
      fontSize: '1.3vw',
      lineHeight: '121%',
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: 123,
      fontSize: 20,
      lineHeight: '24px',
    },
    '& b': {
      lineHeight: '10px',
      [theme.breakpoints.up('tablet')]: {
        lineHeight: '20px',
      },
      [theme.breakpoints.up('desktopXL')]: {
        lineHeight: '30px',
      },
    },
  },
  dubsmash: {
    left: 303.09,
    top: 470.09,
    [theme.breakpoints.up('tablet')]: {
      left: '80.939vw',
      top: '24.754vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 1200,
      top: 167,
    },
  },
  dubsmashText: {
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.33vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  apollo: {
    left: 235.99,
    top: 614.7,
    [theme.breakpoints.up('tablet')]: {
      left: '57.4vw',
      top: '43.37vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 851,
      top: 443,
    },
  },
  amazonReviews: {
    left: 83.48,
    top: 558.28,
    [theme.breakpoints.up('tablet')]: {
      left: '25.428vw',
      top: '35.681vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 377,
      top: 329,
    },
  },
  amazonReviewsText: {
    width: 39.72,
    lineHeight: '8px',
    [theme.breakpoints.up('tablet')]: {
      width: '7.824vw',
      lineHeight: '1.5vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: 116,
      lineHeight: '22.6px',
    },
    '& b': {
      lineHeight: '10px',
      [theme.breakpoints.up('tablet')]: {
        lineHeight: '2.0vw',
      },
      [theme.breakpoints.up('desktopXL')]: {
        lineHeight: '30px',
      },
    },
  },
  ebay: {
    left: 169.08,
    top: 722.47,
    [theme.breakpoints.up('tablet')]: {
      left: '36.153vw',
      top: '51.734vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 536,
      top: 567,
    },
  },
  twitch: {
    left: 111.71,
    top: 745.73,
    [theme.breakpoints.up('tablet')]: {
      left: '28.261vw',
      top: '60.705vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 419,
      top: 700,
    },
  },
  experianBrazil: {
    left: 56.25,
    top: 780.5,
    [theme.breakpoints.up('tablet')]: {
      left: '5.329vw',
      top: '54.971vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 79,
      top: 615,
    },
  },
  experianBrazilText: {
    fontSize: 6,
    lineHeight: '9px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.2vw',
      lineHeight: '1.8vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 18,
      lineHeight: '27px',
    },
  },
  JPmorganChase: {
    left: 154.8,
    top: 575.93,
    [theme.breakpoints.up('tablet')]: {
      left: '44.119vw',
      top: '40.807vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 663,
      top: 405,
    },
  },
  JPmorganChaseText: {
    fontSize: 5.14,
    lineHeight: '8px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.95vw',
      lineHeight: '1.4vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 15,
      lineHeight: '22.5px',
    },
  },
  mySpace: {
    left: 17.29,
    top: 529.26,
    [theme.breakpoints.up('tablet')]: {
      left: '5.329vw',
      top: '21.381vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 79,
      top: 117,
    },
  },
  equifax: {
    left: 55.23,
    top: 508.55,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '22.866vw',
      top: '26.575vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 339,
      top: 194,
    },
  },
  equifaxText: {
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.94vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  quora: {
    left: 282.2,
    top: 428,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '75.206vw',
      top: '18.818vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 1115,
      top: 79,
    },
  },
  quoraText: {
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.85vw',
      lineHeight: '1.3vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  capitalOne: {
    left: 309.42,
    top: 641.93,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '81.951vw',
      top: '36.7vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 1215,
      top: 345,
    },
  },
  uber: {
    left: 208.4,
    top: 558.53,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '53.15vw',
      top: '34.062vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 788,
      top: 305,
    },
  },
  uberText: {
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.95vw',
      lineHeight: '1.4vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  friendFinderNetwork: {
    left: 126.21,
    top: 622.06,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '34.534vw',
      top: '43.033vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 512,
      top: 438,
    },
  },
  friendFinderNetworkText: {
    width: 31.85,
    fontSize: 4.79,
    lineHeight: '6px',
    [theme.breakpoints.up('tablet')]: {
      width: '6.273vw',
      fontSize: '0.95vw',
      lineHeight: '128%',
    },
    [theme.breakpoints.up('desktopXL')]: {
      width: 93,
      fontSize: 14,
      lineHeight: '18px',
    },
    '& b': {
      lineHeight: '7px',
      [theme.breakpoints.up('tablet')]: {
        lineHeight: '1.4vw',
      },
      [theme.breakpoints.up('desktopXL')]: {
        lineHeight: '21px',
      },
    },
  },
  amorGames: {
    left: 20.23,
    top: 701.58,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '14.097vw',
      top: '49.98vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 209,
      top: 541,
    },
  },
  amorGamesText: {
    fontSize: 4.79,
    lineHeight: '7px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.94vw',
      lineHeight: '1.4vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  dropbox: {
    left: 46.94,
    top: 736.86,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '19.358vw',
      top: '59.963vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 287,
      top: 689,
    },
  },
  adobe: {
    left: 169.08,
    top: 782.41,
    [theme.breakpoints.up('tablet')]: {
      left: '43.303vw',
      top: '63.2vw',
      background: 'rgba(255, 255, 255, 0.0)',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 642,
      top: 737,
    },
  },
  linkedin: {
    left: 312.83,
    top: 772.95,
    background: 'transparent',
    [theme.breakpoints.up('tablet')]: {
      left: '61.986vw',
      top: '54.432vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 919,
      top: 607,
    },
  },
  MGMhotels: {
    left: 223.53,
    top: 758.89,
    [theme.breakpoints.up('tablet')]: {
      left: '63.648vw',
      top: '62.324vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      left: 943.64,
      top: 724,
    },
  },
}))
export default function CircleChart() {
  const classes = useStyles()
  return (
    <>
      <Circle
        size='1'
        name='Facebook'
        price='533,000,000'
        className={classes.facebook}
      />
      <Circle
        size='2'
        name='Syniverse'
        price='500,000,000'
        className={classes.syniverse}
        className2={classes.syniverseText}
      />
      <Circle
        size='2'
        name='Marriott Hotels'
        price='5,200,000'
        className={classes.marriott}
        className2={classes.marriottText}
        green
      />
      <Circle
        size='3'
        name='Twitter'
        price='330,000,000'
        className={classes.twitter}
      />
      <Circle
        size='4'
        name='Microsoft'
        price='250,000,000'
        className={classes.microsoft}
      />
      <Circle
        size='5'
        name='Canva'
        price='139,000,000'
        className={classes.canva}
      />
      <Circle
        size='6'
        name='Yahoo'
        price='32,000,000'
        className={classes.yahoo}
      />
      <Circle
        size='6'
        name='Deep Root Analytics'
        price='198,000,000'
        className={classes.deepRootAnalytics}
        className2={classes.deepRootAnalyticsText}
      />
      <Circle
        size='6'
        name='Dubsmash'
        price='162,000,000'
        className={classes.dubsmash}
        className2={classes.dubsmashText}
      />
      <Circle
        size='6'
        name='Apollo'
        price='200,000,000'
        className={classes.apollo}
        className2={classes.dubsmashText}
      />
      <Circle
        size='6'
        name='Amazon Reviews'
        price='13,124,962'
        className={classes.amazonReviews}
        className2={classes.amazonReviewsText}
      />
      <Circle
        size='6'
        name='Ebay'
        price='145,000,000'
        className={classes.ebay}
      />
      <Circle
        size='6'
        name='Twitch'
        price='10,000,000'
        className={classes.twitch}
      />
      <Circle
        size='6'
        name='Experian Brazil'
        price='220,000,000'
        className={classes.experianBrazil}
        className2={classes.experianBrazilText}
      />
      <Circle
        size='6'
        name='JP Morgan Chase'
        price='76,000,000'
        className={classes.JPmorganChase}
        className2={classes.JPmorganChaseText}
      />
      <Circle
        size='7'
        name='MySpace'
        price='164,000,000'
        className={classes.mySpace}
      />
      <Circle
        size='7'
        name='Equifax'
        price='143,000,000'
        className={classes.equifax}
        className2={classes.equifaxText}
      />
      <Circle
        size='7'
        name='Quora'
        price='100,000,000'
        className={classes.quora}
        className2={classes.quoraText}
      />
      <Circle
        size='7'
        name='Capital One'
        price='100,000,000'
        className={classes.capitalOne}
        className2={classes.quoraText}
      />
      <Circle
        size='7'
        name='Uber'
        price='57,000,000'
        className={classes.uber}
        className2={classes.uberText}
      />
      <Circle
        size='7'
        name='Friend Finder Network'
        price='412,000,000'
        className={classes.friendFinderNetwork}
        className2={classes.friendFinderNetworkText}
      />
      <Circle
        size='7'
        name='Amor Games'
        price='11,000,000'
        className={classes.amorGames}
        className2={classes.amorGamesText}
      />
      <Circle
        size='7'
        name='Dropbox'
        price='68,700,000'
        className={classes.dropbox}
        className2={classes.amorGamesText}
      />
      <Circle
        size='7'
        name='Adobe'
        price='38,000,000'
        className={classes.adobe}
        className2={classes.amorGamesText}
      />
      <Circle
        size='7'
        name='Linkedin'
        price='117,000,000'
        className={classes.linkedin}
        className2={classes.amorGamesText}
      />
      <Circle
        size='7'
        name='MGM Hotels'
        price='10,600,000'
        className={classes.MGMhotels}
        className2={classes.amorGamesText}
      />
    </>
  )
}
