import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Fade } from 'react-awesome-reveal'
import { StaticImage } from 'gatsby-plugin-image'
import ToolItem from '../../../components/ToolItem'

const shieldImage = '../../../assets/Home/Images/ToolSection/Shield.svg'
const coinImage = '../../../assets/Home/Images/ToolSection/Coin.svg'
const stockImage = '../../../assets/Home/Images/ToolSection/Stock.svg'
const toolImage = '../../../assets/Home/Images/ToolSection/Tool.svg'
const serverImage = '../../../assets/Home/Images/ToolSection/Server.svg'
const personImage = '../../../assets/Home/Images/ToolSection/Person.svg'
const useStyles = makeStyles((theme) => ({
  toolsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '70px 16px',
    [theme.breakpoints.up('tabletV2')]: { padding: '70px 70px' },
    [theme.breakpoints.up('desktopXL')]: {
      justifyContent: 'center',
    },
  },
  headingText: {
    fontFamily: 'poppins',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '25.2px',
    [theme.breakpoints.up('mobile')]: {
      margin: '20px 4vw',
    },
    [theme.breakpoints.up('tabletV2')]: {
      margin: '0px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 26.5,
      lineHeight: '37px',
      width: 869,
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 34,
      lineHeight: '47px',
      width: 1141,
    },
  },
  toolsContainer: {
    display: 'grid',
    marginTop: 10,
    width: '100%',
    [theme.breakpoints.up('mobile')]: {
      gridTemplateColumns: '1fr',
      gridGap: '20px',
    },

    [theme.breakpoints.up('tabletV2')]: {
      gridGap: '20px 30px',
      gridTemplateColumns: '1fr 1fr',
      marginTop: 38,
    },

    [theme.breakpoints.up('desktop')]: {
      gridGap: '30px 40px',
      marginTop: 50,
    },
  },
  iconWrapper: {
    width: '13vw',
    height: '13vw',
    maxWidth: '55px',
    maxHeight: '55px',

    [theme.breakpoints.up('tablet')]: { maxWidth: '55px', maxHeight: '55px' },

    [theme.breakpoints.up('desktop')]: {},
  },
  infoContainer: {
    boxShadow: '0px 20px 50px rgba(18, 17, 30, 0.08);',
    borderRadius: '10px',
    marginTop: 20,
    padding: '31px 21px 27px',

    [theme.breakpoints.up('tabletV2')]: {
      marginTop: 20,
      padding: '31px 51px 27px',
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 40,
      padding: '57px 108px',
    },
  },
  greenLine: {
    width: 32.5,
    height: 3,
    backgroundColor: '#2FA43B',
  },
  infoTitle: {
    fontFamily: 'poppins',
    fontWeight: 600,
    marginTop: 20,
    fontSize: 16,
    lineHeight: '21.9px',

    [theme.breakpoints.up('desktop')]: {
      fontSize: 18,
      lineHeight: '23px',
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 25,
      lineHeight: '37.5px',
    },
  },
  p1: {
    fontFamily: 'poppins',
    fontWeight: 500,
    color: '#737373',

    [theme.breakpoints.up('mobile')]: {
      fontSize: 12,
      lineHeight: '18.6px',
      marginTop: 10,
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
      lineHeight: '18px',
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginTop: 15,
    },
  },
  p2: {
    fontFamily: 'poppins',
    fontWeight: 500,
    color: '#737373',
    fontSize: 12,
    lineHeight: '18.6px',
    marginTop: 15,

    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
      lineHeight: '18px',
      marginTop: 15,
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginTop: 25,
    },
  },
  p3: {
    fontFamily: 'poppins',
    fontWeight: 500,
    color: '#737373',
    fontSize: 12,
    lineHeight: '18.6px',
    marginTop: 15,

    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
      lineHeight: '18px',
      marginTop: 15,
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 16,
      lineHeight: '24px',
      marginTop: 25,
    },
  },
}))

export default function ToolsSection() {
  const classes = useStyles()
  const tools = [
    {
      imageComponent: (
        <StaticImage
          src={shieldImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Reduce Liability & Risk',
      text: 'Data hacks are costly. There is the reputational risk, cost of potential lost revenue and perhaps litigation. CycurID can help you avoid these costs and risks.',
    },
    {
      imageComponent: (
        <StaticImage
          src={coinImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Reduce Fraud Costs',
      text: 'Fraud is expensive. It is estimated that for every $1 cost of fraud incurred, it costs over $3 to remedy. With CycurID you can rest assured that your customers are real, authentic and have been verified.',
    },
    {
      imageComponent: (
        <StaticImage
          src={personImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Preset On-boarding Criteria',
      text: 'Your business is unique. Preset your on-boarding and KYC/AML protocols. Save verification costs and legal issues by preventing unqualified customers from signing up on your platform.',
    },
    {
      imageComponent: (
        <StaticImage
          src={toolImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Auditing Tools',
      text: 'Reduce reporting costs with CycurID’s automated auditing tools. Ensure that your compliance team and auditors know your customer data is accurate, current and verified.',
    },
    {
      imageComponent: (
        <StaticImage
          src={serverImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Is Your Customer Data Current? ',
      text: 'Your KYC/AML procedures are only as valid as how current your customers’ data is. CycurID’s unique auto data update ensures that your customer data is current, accurate and valid. All without cost to you.',
    },
    {
      imageComponent: (
        <StaticImage
          src={stockImage}
          alt='CycurID checkmark'
          loading='eager'
          quality={100}
          className={classes.iconWrapper}
        />
      ),
      title: 'Focused Customer Acquisition Costs',
      text: 'Be 100% certain that your customers are who they say they are. The CycurID marketplace enables you to direct and focus your customer acquisition spending. ',
      customTextWidth: true,
    },
  ]

  return (
    <section className={classes.toolsSection}>
      <Fade triggerOnce fraction={0.3} delay={200}>
        <Typography variant='h2' className={classes.headingText}>
          CycurID’s proprietary software provides the tools you need to focus on
          generating revenue
        </Typography>
      </Fade>
      <div className={classes.toolsContainer}>
        <Fade triggerOnce cascade duration={500} damping={0.3}>
          {tools.map(
            ({ imageComponent, title, text, customTextWidth, index }) => (
              <ToolItem
                key={index}
                imageComponent={imageComponent}
                title={title}
                text={text}
                customTextWidth={customTextWidth}
              />
            ),
          )}
        </Fade>
      </div>
      <Fade triggerOnce fraction={0.3} delay={200}>
        <div className={classes.infoContainer}>
          <div className={classes.greenLine} />
          <Typography className={classes.infoTitle}>
            We believe that everyone should own and control their identity.
          </Typography>
          <Typography className={classes.p1}>
            CycurID is the next generation of personalized identity management
            software. We are much more than a KYC/AML provider or a document
            verification and authentication service.
          </Typography>
          <Typography className={classes.p2}>
            The flexibility of the CycurID solution allows for development of
            identity based applications that are not currently available in the
            market today. We are developing identity based solutions that will
            redefine the authentication and exchange of goods, services and
            communication on a global scale.
          </Typography>
          <Typography className={classes.p3}>
            Sound innovative? It is. Sign up to keep apprised of what we are
            doing and where we are going.
          </Typography>
        </div>
      </Fade>
    </section>
  )
}
