import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  circle: {
    position: 'absolute',
    width: (props) =>
      (props.size === '1' && 120.89) ||
      (props.size === '2' && 102.39) ||
      (props.size === '3' && 93.83) ||
      (props.size === '4' && 75.68) ||
      (props.size === '5' && 67.12) ||
      (props.size === '6' && 54.45) ||
      (props.size === '7' && 41.78),
    height: (props) =>
      (props.size === '1' && 120.89) ||
      (props.size === '2' && 102.39) ||
      (props.size === '3' && 93.83) ||
      (props.size === '4' && 75.68) ||
      (props.size === '5' && 67.12) ||
      (props.size === '6' && 54.45) ||
      (props.size === '7' && 41.78),
    background: (props) =>
      (props.size === '1' && 'rgba(25, 49, 62, 0.9)') ||
      (props.size === '2' && props.green && '#2FA43B') ||
      (props.size === '2' && 'rgba(25, 49, 62, 0.98)') ||
      (props.size === '3' && '#19313E') ||
      (props.size === '4' && 'rgba(25, 49, 62, 0.9)') ||
      (props.size === '5' && '#5ABF61') ||
      (props.size === '6' && 'rgba(230, 230, 230, 0.3)') ||
      (props.size === '7' && 'rgba(255, 255, 255, 0.8)'),
    borderRadius: '60.4433px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: (props) => props.shadow && '0px 0px 4px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('tablet')]: {
      position: 'absolute',
      width: (props) =>
        (props.size === '1' && '23.81vw') ||
        (props.size === '2' && '20.167vw') ||
        (props.size === '3' && '18.481vw') ||
        (props.size === '4' && '14.906vw') ||
        (props.size === '5' && '13.22vw') ||
        (props.size === '6' && '10.724vw') ||
        (props.size === '7' && '8.229vw'),
      height: (props) =>
        (props.size === '1' && '23.81vw') ||
        (props.size === '2' && '20.167vw') ||
        (props.size === '3' && '18.481vw') ||
        (props.size === '4' && '14.906vw') ||
        (props.size === '5' && '13.22vw') ||
        (props.size === '6' && '10.724vw') ||
        (props.size === '7' && '8.229vw'),
      background: (props) =>
        (props.size === '2' && props.green && '#2FA43B') ||
        (props.size === '2' && 'rgba(25, 49, 62, 0.9)'),
      borderRadius: '11.9048vw',
    },
    [theme.breakpoints.up('desktopXL')]: {
      position: 'absolute',
      width: (props) =>
        (props.size === '1' && 353.3) ||
        (props.size === '2' && 299) ||
        (props.size === '3' && 274) ||
        (props.size === '4' && 221) ||
        (props.size === '5' && 196) ||
        (props.size === '6' && 159) ||
        (props.size === '7' && 122),
      height: (props) =>
        (props.size === '1' && 353.3) ||
        (props.size === '2' && 299) ||
        (props.size === '3' && 274) ||
        (props.size === '4' && 221) ||
        (props.size === '5' && 196) ||
        (props.size === '6' && 159) ||
        (props.size === '7' && 122),
      background: (props) =>
        (props.size === '2' && props.green && '#2FA43B') ||
        (props.size === '2' && 'rgba(25, 49, 62, 0.98)'),
      borderRadius: '176.5px',
    },
  },
  circleText: {
    fontSize: (props) =>
      (props.size === '1' && 13.6) ||
      (props.size === '2' && props.green && 10.2) ||
      (props.size === '2' && 14.4) ||
      (props.size === '3' && 10.3) ||
      (props.size === '4' && 8.5) ||
      (props.size === '5' && 8.5) ||
      (props.size === '6' && 6.85) ||
      (props.size === '7' && 4.79),
    fontWeight: 300,
    lineHeight: (props) =>
      (props.size === '1' && '21px') ||
      (props.size === '2' && props.green && '15px') ||
      (props.size === '2' && '22px') ||
      (props.size === '3' && '15px') ||
      (props.size === '4' && '13px') ||
      (props.size === '5' && '13px') ||
      (props.size === '6' && '10px') ||
      (props.size === '7' && '7px'),
    color: (props) =>
      (props.size === '1' && '#FFFFFF') ||
      (props.size === '2' && '#FFFFFF') ||
      (props.size === '3' && '#FFFFFF') ||
      (props.size === '4' && '#FFFFFF') ||
      (props.size === '5' && '#FFFFFF') ||
      (props.size === '6' && 'rgba(0, 0, 0, 0.7)') ||
      (props.size === '7' && 'rgba(0, 0, 0, 0.7)'),
    [theme.breakpoints.up('tablet')]: {
      fontSize: (props) =>
        (props.size === '1' && '2.7vw') ||
        (props.size === '2' && props.green && '2.0vw') ||
        (props.size === '2' && '2.8vw') ||
        (props.size === '3' && '2.0vw') ||
        (props.size === '4' && '1.7vw') ||
        (props.size === '5' && '1.7vw') ||
        (props.size === '6' && '1.35vw') ||
        (props.size === '7' && '0.9vw'),
      lineHeight: (props) =>
        (props.size === '1' && '4vw') ||
        (props.size === '2' && props.green && '3.0vw') ||
        (props.size === '2' && '4.2vw') ||
        (props.size === '3' && '3.0vw') ||
        (props.size === '4' && '2.5vw') ||
        (props.size === '5' && '2.5vw') ||
        (props.size === '6' && '2.0vw') ||
        (props.size === '7' && '1.4vw'),
    },
    [theme.breakpoints.up('desktopXL')]: {
      fontSize: (props) =>
        (props.size === '1' && 40) ||
        (props.size === '2' && props.green && 30) ||
        (props.size === '2' && 42) ||
        (props.size === '3' && 30) ||
        (props.size === '4' && 25) ||
        (props.size === '5' && 25) ||
        (props.size === '6' && 20) ||
        (props.size === '7' && 14),
      lineHeight: (props) =>
        (props.size === '1' && '60px') ||
        (props.size === '2' && props.green && '45px') ||
        (props.size === '2' && '63px') ||
        (props.size === '3' && '45px') ||
        (props.size === '4' && '38px') ||
        (props.size === '5' && '38px') ||
        (props.size === '6' && '30px') ||
        (props.size === '7' && '21px'),
    },
    '& b': {
      fontWeight: 700,
    },
  },
}))

export default function Circle({
  size,
  name,
  price,
  className,
  className2,
  green,
}) {
  const classes = useStyles({ size, green })
  return (
    <div className={`${classes.circle} ${className}`}>
      <Typography
        variant='h1'
        className={`${classes.circleText} ${className2}`}
      >
        {name}
        <br />
        <b>{price}</b>
      </Typography>
    </div>
  )
}

Circle.propTypes = {
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7']),
  name: PropTypes.string,
  price: PropTypes.string,
  className: PropTypes.string,
  className2: PropTypes.string,
  green: PropTypes.bool,
}

Circle.defaultProps = {
  size: '1',
  name: '',
  price: '',
  className: '',
  className2: '',
  green: false,
}
