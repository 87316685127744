import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
// import { Link } from 'gatsby'
// import { Fade } from 'react-awesome-reveal'
// import { StaticImage, getImage } from 'gatsby-plugin-image'
// import { useTheme } from '@material-ui/styles'
// import CommIcon from '../assets/Nav/Icons/secure_comm.svg'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  toolContainer: {
    display: 'flex',
    blend: 'pass through',
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.05);',
    borderRadius: 15,
    width: '100%',
    padding: '4vw',
    [theme.breakpoints.up('mobile')]: {
      height: 197,
    },
    [theme.breakpoints.up('tabletV2')]: {
      height: '220px',
    },
    [theme.breakpoints.up('desktop')]: {
      height: 223,
      padding: '5%',
    },
  },
  headingText: {
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 10,

    [theme.breakpoints.up('tabletV2')]: {
      fontSize: 12.7,
      lineHeight: '17px',
      marginBottom: 15,
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 19,
      lineHeight: '28.5px',
      marginBottom: 20,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '5%',
    [theme.breakpoints.up('tabletV2')]: {
      paddingRight: '10%',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '5%',
    },
  },
  descriptionText: {
    fontFamily: 'poppins',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18.5px',
    color: '#737373',
    paddingRight: '15px',
    [theme.breakpoints.up('tabletV2')]: {
      fontSize: 10,
      lineHeight: '15.5px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 12.7,
      lineHeight: '19px',
    },

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '80%',
    [theme.breakpoints.up('tabletV2')]: {
      justifyContent: 'flex-start',
      marginTop: '10px',
    },
  },
}))

function ToolItem({ imageComponent, title, text, ...props }) {
  const classes = useStyles()

  return (
    <div className={classes.toolContainer}>
      <div className={classes.imageContainer}>{imageComponent}</div>
      <div className={classes.textContainer}>
        <Typography className={classes.headingText}>{title}</Typography>{' '}
        <Typography className={classes.descriptionText}>{text}</Typography>
      </div>
    </div>
  )
}

export default ToolItem

ToolItem.propTypes = {
  imageComponent: PropTypes.element,
  title: PropTypes.string,
  text: PropTypes.string,
}

ToolItem.defaultProps = {
  imageComponent: <div />,
  title: '',
  text: '',
}
