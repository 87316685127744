import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Fade } from 'react-awesome-reveal'
import BusinessSection from '../sections/Home/03-BusinessV2/BusinessSectionV2'
import ContactUs from '../components/ContactUs'
import ProprietarySection from '../sections/Home/05-Proprietary/proprietarySection'
import IndividualSection from '../sections/Home/06-Individual/individual'
import ToolsSection from '../sections/Home/04-ToolsSection'
import CircleChart from '../components/CircleChart'
import MobileSection from '../sections/Home/MobileSection/MobileSection'
import Business from './products/business'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '120px auto 0px auto',
    maxWidth: '370px',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'center',
      maxWidth: '1023px',
      margin: '100px auto 0px auto',
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'center',
      margin: '160px auto 0px auto',
      maxWidth: '1600px',
    },
  },
  titleContainer: {
    width: '100%',
  },
  heroText: {
    color: 'black',
    fontSize: 'calc(31px + 0.4vw)',
    textAlign: 'left',
    lineHeight: 'calc(40px + 0.5vw)',
    maxWidth: 340,

    [theme.breakpoints.up('tablet')]: {
      maxWidth: '85.7vw',
      lineHeight: '5.9vw',
      textAlign: 'center',
      fontSize: '4.3vw',
    },

    [theme.breakpoints.up('1300')]: {
      textAlign: 'center',
      lineHeight: '120%',
      maxWidth: 1143,
      fontSize: '56px',
    },
  },
  circleChart: {
    position: 'relative',
    top: -420,
    width: 370,
    height: 400,
    [theme.breakpoints.up('tablet')]: {
      position: 'relative',
      top: -50,
      width: '100vw',
      height: '75vw',
    },
    [theme.breakpoints.up('desktop')]: {
      top: -100,
    },
    [theme.breakpoints.up('desktopXL')]: {
      position: 'relative',
      top: 0,
      width: 1440,
      height: 1000,
    },
  },
  costContainer: {
    marginTop: 65,
    width: '100vw',
    height: 57,
    background: 'white',
    boxShadow: '0px 2.51425px 12.5712px rgba(0, 0, 0, 0.14)',
    borderRadius: '2.51425px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('tablet')]: {
      marginTop: -20,
      height: '15.6vw',
      boxShadow: '0px 7.2041px 36.0205px rgba(0, 0, 0, 0.14)',
      borderRadius: '7.2041px',
    },

    [theme.breakpoints.up('desktop')]: {},

    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 1600,
      height: 221.5,
      boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.14)',
      borderRadius: '10px',
    },
  },
  costText: {
    fontSize: 'calc(16px + 0.1vw)',
    fontWeight: 700,
    lineHeight: '120%',
    color: '#000000',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '4.5vw',
    },

    [theme.breakpoints.up('desktop')]: {},

    [theme.breakpoints.up('desktopXL')]: {
      fontSize: '65px',
      lineHeight: '78px',
    },
  },
  ctaSection: {
    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      margin: 'auto',
      marginBottom: -145,
      marginTop: 30,
    },
  },
  mainContainer: {
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: theme.custom.maxWidth,
    },
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
  },
  proprietarySectionContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))

export default function Home() {
  const classes = useStyles()

  return (
    <>
      <Business/>
    </>
  )
}
