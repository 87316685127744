import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Fade } from 'react-awesome-reveal'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../../../components/Divider'

import WarningIcon from '../../../assets/Home/Images/BusinessSection/warn.svg'
import HackerIcon from '../../../assets/Home/Images/BusinessSection/hacker.svg'
import SearchIcon from '../../../assets/Home/Images/BusinessSection/search.svg'

import CTALink from '../../../components/CTALink'

import routes from '../../../routes'
import CustomFadeSlide from '../../../components/RevealAnim/CustomFadeSlide'

const DashboardScreen =
  '../../../assets/Home/Images/dashboard_User_Onboarding.png'
const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Componenets
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    marginLeft: '10%',
    marginRight: '10%',
    textAlign: 'center',
    lineHeight: '27.5px',
    [theme.breakpoints.up('tablet')]: {},

    [theme.breakpoints.up('desktop')]: {
      lineHeight: '38px',
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',

    [theme.breakpoints.up('tabletV2')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Applicable Industries Section
   * ======================================= */
  industrySection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    margin: '130px auto',

    [theme.breakpoints.up('desktop')]: {
      margin: '150px auto',
    },
  },
  industryCardContainer: {
    display: 'grid',
    gridTemplate: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr / 1fr',
    placeItems: 'center',

    marginTop: 50,
    rowGap: 20,
    width: '100%',
    maxWidth: 1050,

    [theme.breakpoints.up('desktop')]: {
      gridTemplate: '1fr 1fr 1fr / 1fr 1fr 1fr',

      rowGap: 40,
    },
  },
  industryCard: {
    display: 'flex',
    alignItems: 'center',

    padding: '20px 30px',
    width: 300,

    border: '1px solid #E1E1E1',
    borderRadius: 5,
  },
  industryCardIcon: {
    width: 25,
    height: 25,
    marginRight: 20,

    color: '#2FA43B',
  },
  industryCardLabel: {
    color: 'black',
  },

  /**
   * || Data and Identity Management Section
   * ======================================= */
  dataManagementSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('tablet')]: {},
  },
  dataManagementFeatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 50,
    [theme.breakpoints.up('tabletV2')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  dataManagementFeature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: 245,
    margin: '50px auto 0px auto',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      margin: '50px 50px 40px 50px',
    },

    [theme.breakpoints.up('desktop')]: {
      margin: '50px 60px 40px 60px',
      maxWidth: 290,
    },
  },

  /**
   * || CycurID Dashboard Section
   * ======================================= */
  dashboardSectionWrapper: {
    margin: 'auto',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.up('mobile')]: {},

    [theme.breakpoints.up('desktop')]: {
      marginTop: 100,
      marginBottom: 50,
    },
  },

  dashboardSection: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: theme.custom.maxWidth,
    // padding: '0px 55px',
    paddingBottom: 50,
    width: '100%',
    backgroundColor: 'rgba(25, 49, 62, 1)',
    // background:
    //   'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    [theme.breakpoints.up('mobile')]: {},
    [theme.breakpoints.up('tabletV2')]: {
      flexDirection: 'row',
      alignItems: 'center',
      background: 'transparent',

      zIndex: 1,
    },

    [theme.breakpoints.up('desktopXL')]: {
      margin: 'auto',
    },
  },
  dashboardBackground: {
    display: 'none',

    [theme.breakpoints.up('tablet')]: {
      display: 'block',
      position: 'absolute',
      width: '85%',
      height: '100%',
      backgroundColor: 'rgba(25, 49, 62, 1)',
      //   background:
      //     'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

      zIndex: -1,
    },
  },
  dashboardDescription: {
    marginTop: 15,
    maxWidth: 500,
    color: '#737373',
  },
  dashboardColumnLeft: {
    // margin: '0px auto',
    // padding: '30px 30px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    // marginLeft: '60px',
    margin: 0,
    [theme.breakpoints.up('mobile')]: {
      margin: 0,
    },
    [theme.breakpoints.up('tabletV2')]: {
      maxWidth: '90%',
      alignItems: 'flex-start',
      marginLeft: '40px',
    },

    [theme.breakpoints.up('desktop')]: {
      minWidth: 525,

      position: 'relative',

      maxWidth: 470,

      left: '5%',
    },
    [theme.breakpoints.up('1100')]: {
      //   padding: '45px 60px',
      left: '10%',
    },

    [theme.breakpoints.up('1200')]: {
      //   padding: '45px 60px',
      left: '15%',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dashboardImg: {
    dispaly: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    height: 'auto',
    width: '95%',

    [theme.breakpoints.up('mobile')]: {
      height: 310,
    },
    [theme.breakpoints.up('500')]: {
      height: 360,
    },
    [theme.breakpoints.up('600')]: {
      height: 410,
    },
    [theme.breakpoints.up('tabletV2')]: {
      height: '300px',
      marginTop: 60,
      width: '300px',
      marginBottom: 60,
    },
    [theme.breakpoints.up('850')]: {
      width: '350px',
    },
    [theme.breakpoints.up('900')]: {
      width: '400px',
    },

    [theme.breakpoints.up('desktop')]: {
      height: 'auto',
      width: '90%',
    },

    [theme.breakpoints.up('1180')]: { width: '75%', maxHeight: 500 },

    [theme.breakpoints.up('1280')]: {},

    [theme.breakpoints.up('1380')]: {},

    [theme.breakpoints.up('1430')]: {},

    [theme.breakpoints.up('1480')]: {},
  },

  dashboardFeature: {
    marginTop: '30px',
    maxWidth: 500,
  },
  dashboardFeatureTitleRow: {
    display: 'flex',
    alignItems: 'center',

    marginBottom: 10,
  },
  dashboardFeatureIcon: {
    width: 25,
    height: 'auto',
    marginRight: 10,

    [theme.breakpoints.up('desktop')]: {
      width: 35,
    },
  },
  bar: {
    marginBottom: 15,
    lineHeight: '27px',
  },
  greenTitle: {
    color: '#2FA43B',
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '17px',
    letterSpacing: '0.5px',
    padding: '0 40px',
    textAlign: 'center',
    marginTop: 60,
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
      marginTop: 60,
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
      marginTop: 80,
    },
  },
  titleText: {
    fontFamily: 'poppins',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
    color: '#FFFFFF',
    padding: '0 40px',
    textAlign: 'center',
    marginTop: 5,
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('1330')]: {
      fontSize: 22,
    },
  },
  contentText: {
    fontFamily: 'poppins',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
    color: '#FFFFFF',
    padding: '0 40px',
    textAlign: 'center',
    marginTop: 10,
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
      marginTop: 10,
    },
    [theme.breakpoints.up('tabletV2')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: 13,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('1200')]: {
      fontSize: 14,
      width: 600,
    },
    [theme.breakpoints.up('1330')]: {
      fontSize: 16,
    },
  },
  dashboardContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: theme.custom.maxWidth,

    width: '100%',
    backgroundColor: 'rgba(25, 49, 62, 1)',
    // background:
    //   'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tabletV2')]: {
      flexDirection: 'row',
      alignItems: 'center',
      background: 'transparent',

      zIndex: 1,
    },

    [theme.breakpoints.up('desktopXL')]: {
      margin: 'auto',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 58,
    padding: '0 40px',
    [theme.breakpoints.up('mobile')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('tabletV2')]: {
      marginTop: '4%',
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      marginTop: '5%',
    },
    [theme.breakpoints.up('1200')]: {
      marginTop: '8%',
    },
  },
  signUpButton: {
    width: '90%',
    marginBottom: 30,
    fontSize: '16px',

    [theme.breakpoints.up('tabletV2')]: {
      fontSize: '14px',
      fontWeight: 500,
      padding: 0,
      marginRight: 16,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '136px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      padding: 0,
      marginRight: 10,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '160px',
    },
  },
}))
export default function BusinessSection() {
  const classes = useStyles()

  return (
    <>
      <section className={classes.dataManagementSection}>
        <Fade triggerOnce fraction={0.3} delay={200}>
          <Divider className={classes.bar} />
          <Typography variant='h2' className={`${classes.sectionTitle}`}>
            CycurID’s patent pending Personal Identification Management System
            (PIMS) utilizes unique biometric encryption systems and file
            storage.
          </Typography>
        </Fade>
        <div className={classes.dataManagementFeatureContainer}>
          <Fade triggerOnce delay={200} cascade duration={600}>
            <div className={classes.dataManagementFeature}>
              <HackerIcon color='#2FA43B' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8 }}
              >
                Eliminate Fraud
              </Typography>
              <Typography variant='body2' style={{ color: '#737373' }}>
                Never let another person impersonate you again.
              </Typography>
              {/* <LinkButton>Learn More</LinkButton> */}
            </div>
            <div className={classes.dataManagementFeature}>
              <SearchIcon color='#2FA43B' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8 }}
              >
                Verify People and Businesses
              </Typography>
              <Typography variant='body2' style={{ color: '#737373' }}>
                Connect with verified users and businesses quickly and easily.
              </Typography>
              {/* <LinkButton>Learn More</LinkButton> */}
            </div>
            <div className={classes.dataManagementFeature}>
              <WarningIcon color='#2FA43B' style={{ height: 35 }} />
              <Typography
                variant='h3'
                style={{ marginTop: 10, marginBottom: 8 }}
              >
                Reduce Risk
              </Typography>
              <Typography variant='body2' style={{ color: '#737373' }}>
                Eliminate potential hacks and user information leaks.
              </Typography>
              {/* <LinkButton>Learn More</LinkButton> */}
            </div>
          </Fade>
        </div>
      </section>
      <section className={classes.dashboardSectionWrapper}>
        <div className={classes.dashboardSection}>
          <div className={classes.dashboardBackground} />
          <div className={classes.dashboardContainer}>
            <Fade triggerOnce fraction={0.3}>
              <div className={classes.dashboardColumnLeft}>
                <Typography className={classes.greenTitle}>BUSINESS</Typography>
                <Typography className={classes.titleText}>
                  Protect your business by protecting your bottom line
                </Typography>
                <Typography className={classes.contentText}>
                  Today’s business environment is competitive. Rising costs and
                  increased regulations all beat up on your bottom line. The
                  CycurID solution allows you to reduce operating costs and
                  manage your bottom line by reducing on-boarding time and KYC
                  process, all the while building customer trust and gaining a
                  competitive advantage. CycurID allows you to work on your
                  business, not in it.
                </Typography>
                <div className={classes.buttonContainer}>
                  <CTALink
                    className={classes.signUpButton}
                    href={routes.BUSINESS}
                  >
                    Learn More
                  </CTALink>
                </div>
              </div>
            </Fade>
            <CustomFadeSlide delay={400} direction='right'>
              <div className={classes.imageContainer}>
                <StaticImage
                  src={DashboardScreen}
                  alt='CycurID Dashboard Signup Form'
                  className={classes.dashboardImg}
                  placeholder='blurred'
                  quality={100}
                  objectFit='fill'
                />
              </div>
            </CustomFadeSlide>
          </div>
        </div>
      </section>
    </>
  )
}
